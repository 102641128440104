/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.3.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import store from './store';

// router setup
import router from './routes/router';
import i18n from './i18n';
import './registerServiceWorker';
import { Auth0 } from './auth';

import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URI;
Vue.axios.interceptors.request.use(async function (config) {
  let token = store.getters.authToken;

  if (!token) {
    token = await Auth0.getTokenSilently();
    store.commit('authToken', token);
  }

  if (token) {
    config.headers.common.Authorization = `Bearer ${token}`;
  }

  return config;
});

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

Vue.use(Auth0, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: (h) => h(App),
  router,
  i18n,
  store,
});
