import Vue from 'vue';

const outreachShifters = {
  namespaced: true,
  state: () => ({
    outreachShifters: [],
    outreachJobs: [],
    selectedJobId: '',
    selectedJobTitle: '',
    selectedRange: null,
    outreachClients: [],
    selectedClientId: '',
    sortData: null,
    selectedShifters: [],
    filteredShifters: [],
  }),
  mutations: {
    setOutreachShifters(state, outreachShifters) {
      state.outreachShifters = outreachShifters;
    },
    setOutreachJobs(state, outreachJobs) {
      state.outreachJobs = outreachJobs;
    },
    setOutreachClients(state, outreachClients) {
      state.outreachClients = outreachClients;
    },
    setSelectedJobId(state, jobId) {
      state.selectedJobId = jobId || '';
    },
    setSelectedClientId(state, clientId) {
      state.selectedClientId = clientId || '';
    },
    setSelectedJobTitle(state, title) {
      state.selectedJobTitle = title || '';
    },
    setSelectedRange(state, range) {
      state.selectedRange = range || null;
    },
    setSortData(state, sortData) {
      state.sortData = sortData;
    },
    setSelectedShifters(state, shifters) {
      state.selectedShifters = shifters;
    },
    setFilteredShifters(state, shifters) {
      state.filteredShifters = shifters;
    },
  },
  actions: {
    loadShifters(context) {
      let END_POINT = `/outreach/shifters-in-range/${context.getters.selectedJobId}?range=${context.getters.selectedRange}`;

      if (context.getters.selectedClientId !== '')
        END_POINT += `&clientid=${context.getters.selectedClientId}`;

      Vue.axios
        .get(END_POINT)
        .then(function (response) {
          const data = response.data.shiftersInRange || [];
          if (data.length > 0) {
            context.commit('setOutreachShifters', data);

            context.dispatch('sortTable');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadJobs(context) {
      const END_POINT = '/outreach/jobs';

      Vue.axios
        .get(END_POINT)
        .then(function (response) {
          const data = response.data || [];
          if (data.length > 0) {
            context.commit('setOutreachJobs', data);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadClients(context) {
      const END_POINT = '/outreach/clients';

      Vue.axios
        .get(END_POINT)
        .then(function (response) {
          const data = response.data || [];
          if (data.length > 0) {
            context.commit('setOutreachClients', data);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // createAction(context, data) {
    //   const END_POINT = '/outreach';

    //   const payload = {
    //     firstName: data.firstName,
    //     lastName: data.lastName,
    //     email: data.email,
    //     phone: data.phone,
    //     smsAllowed: data.smsAllowed,
    //     worksiteCode: data.worksiteCode,
    //     deleted: false,
    //   };

    //   Vue.axios
    //     .post(END_POINT, payload, {
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //     })
    //     .then(function (response) {
    //       if (response.status === 201) {
    //         context.dispatch('loadUsers');
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
    setJobIdSelection({ commit, getters }, jobId) {
      const job = getters.outreachJobs.find((job) => job._id === jobId);

      commit('setSelectedJobTitle', job?.title || '');
      commit('setSelectedJobId', jobId);
    },
    setClientIdSelection({ commit }, clientId) {
      commit('setSelectedClientId', clientId);
    },
    setRangeSelection({ commit }, range) {
      commit('setSelectedRange', range);
    },
    setSortData({ commit }, sortData) {
      commit('setSortData', sortData);
    },
    setFilteredShifters({ commit }, shifters) {
      commit('setFilteredShifters', shifters);
    },
    sortTable({ commit, getters }) {
      const sortData = getters.sortData;

      if (!sortData) return;

      const shifters =
        getters.filteredShifters?.length > 0
          ? getters.filteredShifters
          : getters.outreachShifters;

      if (!shifters) return;

      shifters.sort((a, b) => {
        if (!a.hasOwnProperty(sortData.prop)) return 1;
        else if (!b.hasOwnProperty(sortData.prop)) return -1;

        let varA = a[sortData.prop];
        let varB = b[sortData.prop];
        switch (sortData.prop) {
          case 'maxDistance':
            const aDistMatch = varA.match('\\d{2}|\\d');
            const bDistMatch = varB.match('\\d{2}|\\d');

            if (aDistMatch?.length > 0) varA = aDistMatch[0].padStart(2, '0');
            if (bDistMatch?.length > 0) varB = bDistMatch[0].padStart(2, '0');
          case 'weekHours':
            const aWeekMatch = varA.match('Less than 20|\\d+');
            const bWeekMatch = varB.match('Less than 20|\\d+');

            if (aWeekMatch?.length > 0)
              varA = aWeekMatch[0] === 'Less than 20' ? '19' : aWeekMatch[0];

            if (bWeekMatch?.length > 0)
              varB = bWeekMatch[0] === 'Less than 20' ? '19' : bWeekMatch[0];
            break;
        }

        if (typeof varA === 'string') varA = varA.toUpperCase();
        if (typeof varB === 'string') varB = varB.toUpperCase();

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }

        return sortData.order === 'descending' ? comparison * -1 : comparison;
      });

      commit('setOutreachShifters', shifters);
    },
  },
  getters: {
    outreachShifters: (state) => state.outreachShifters,
    outreachJobs: (state) => state.outreachJobs,
    outreachClients: (state) => state.outreachClients,
    selectedJobId: (state) => state.selectedJobId,
    selectedClientId: (state) => state.selectedClientId,
    selectedJobTitle: (state) => state.selectedJobTitle,
    selectedRange: (state) => state.selectedRange,
    sortData: (state) => state.sortData,
    selectedShifters: (state) => state.selectedShifters,
    filteredShifters: (state) => state.filteredShifters,
    interestedPositions: (state) => {
      // Reduce state.outreachShifters.interestedPositions to a single array of positions.
      let positions = state.outreachShifters.reduce((acc, cur) => {
        // Skip empty positions
        if (!cur.interestedPositions) return acc;

        return acc.concat(cur.interestedPositions.split(', '));
      }, []);

      // Remove duplicates
      positions = [...new Set(positions)];

      // Sort positions
      positions.sort();

      return positions.map((position) => {
        return { label: position, value: position };
      });
    },
    previousPositions: (state) => {
      // Reduce state.outreachShifters.previousPositions to a single array of positions.
      let positions = state.outreachShifters.reduce((acc, cur) => {
        // Skip empty positions
        if (!cur.previousPositions) return acc;

        return acc.concat(cur.previousPositions.split(', '));
      }, []);

      // Remove duplicates
      positions = [...new Set(positions)];

      // Sort positions
      positions.sort();

      return positions.map((position) => {
        return { label: position, value: position };
      });
    },
    recentPositions: (state) => {
      // Reduce state.outreachShifters.recentPositions to a single array of positions.
      let positions = state.outreachShifters.reduce((acc, cur) => {
        // Skip empty positions
        if (!cur.recentPositions) return acc;

        return acc.concat(cur.recentPositions.split(', '));
      }, []);

      // Remove duplicates
      positions = [...new Set(positions)];

      // Sort positions
      positions.sort();

      return positions.map((position) => {
        return { label: position, value: position };
      });
    },
  },
};

export default outreachShifters;
