<template>
  <div class="row mt-5">
    <div class="col-12">
      <card card-body-classes="table-full-width">
        <h4 slot="header" class="card-title"></h4>
        <div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-select
              class="select-primary mb-3 pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>

            <!-- <base-input>
              <el-input
                type="search"
                class="mb-3 search-input"
                clearable
                prefix-icon="el-icon-search"
                placeholder="Search"
                v-model="searchQuery"
                aria-controls="datatables"
              >
              </el-input>
            </base-input> -->
          </div>
          <el-table :data="queriedData" @sort-change="sortChange">
            <el-table-column
              v-for="column in columns"
              :key="column.name"
              :prop="toCamelCase(column.name)"
              :label="column.name"
              :min-width="column.minWidth || 0"
              sortable="custom"
              :sort-orders="['ascending', 'descending']"
              :formatter="column.formatter || undefined"
            ></el-table-column>
            <el-table-column label="Actions" :max-width="100">
              <template slot-scope="scope">
                <el-tooltip
                  content="Send"
                  :open-delay="300"
                  placement="top"
                  v-if="sendActionButton"
                >
                  <base-button
                    type="info"
                    aria-label="send action"
                    class="btn-link"
                    size="sm"
                    icon
                    @click="sendAction(scope.$index, scope.row)"
                  >
                    <i class="tim-icons icon-send"></i>
                  </base-button>
                </el-tooltip>
                <el-tooltip
                  content="Edit"
                  :open-delay="300"
                  placement="top"
                  v-if="editActionButton"
                >
                  <base-button
                    type="success"
                    aria-label="edit action"
                    class="btn-link"
                    size="sm"
                    icon
                    @click="editAction(scope.$index, scope.row)"
                    disabled
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                </el-tooltip>
                <el-tooltip
                  content="Delete"
                  :open-delay="300"
                  placement="top"
                  v-if="deleteActionButton"
                >
                  <base-button
                    type="danger"
                    aria-label="delete action"
                    class="btn-link"
                    size="sm"
                    icon
                    @click="deleteAction(scope.$index, scope.row)"
                  >
                    <i class="tim-icons icon-trash-simple"></i>
                  </base-button>
                </el-tooltip>
                <el-tooltip
                  content="View Conversations"
                  :open-delay="300"
                  placement="top"
                  v-if="conversationActionButton"
                >
                  <base-button
                    type="info"
                    aria-label="delete action"
                    class="btn-link"
                    size="sm"
                    icon
                    @click="conversatonAction(scope.$index, scope.row)"
                  >
                    <i class="tim-icons icon-chat-33"></i>
                  </base-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category no-text-transform">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} events
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </base-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';

export default {
  name: 'outreach-events-table',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns',
    },
    rowData: {
      type: Array,
      default: () => [],
      description: 'Table data',
    },
    sendActionButton: {
      type: Boolean,
      default: false,
      descripton: 'Whether or not to show the send action button',
    },
    editActionButton: {
      type: Boolean,
      default: false,
      descripton: 'Whether or not to show the edit action button',
    },
    deleteActionButton: {
      type: Boolean,
      default: false,
      descripton: 'Whether or not to show the delete action button',
    },
    conversationActionButton: {
      type: Boolean,
      default: false,
      description: 'Whether or not to show the conversation action button',
    },
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.rowData, {
      keys: ['title', 'jobName'],
      threshold: 0.3,
    });
  },
  watch: {
    // TODO: Change to server-side filtering.
    searchQuery(value) {
      let result = this.rowData;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
  methods: {
    hasActions() {
      return (
        this.sendActionButton ||
        this.editActionButton ||
        this.deleteActionButton ||
        this.conversationActionButton
      );
    },
    sendAction(index, row) {
      this.$emit('send-action', row.id);
    },
    editAction(index, row) {
      this.$emit('edit-action', row);
    },
    deleteAction(index, row) {
      swal
        .fire({
          title: 'Delete Outreach Event',
          text: `Are you sure you want to delete this outreach event?`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill',
          },
          confirmButtonText: 'Yes',
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            this.$emit('delete-action', row.id);
          }
        });
    },
    conversatonAction(index, row) {
      this.$emit('conversation-action', row.id);
    },
    sortChange(data) {
      this.$emit('sort-action', data.prop, data.order);
    },
    toCamelCase(str) {
      return str
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
    },
  },
  computed: {
    queriedData() {
      let result = this.rowData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.rowData.length;
    },
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0,
      },
      searchQuery: '',
      searchedData: [],
      fuseSearch: null,
    };
  },
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content {
  font-size: inherit !important;
}
</style>
