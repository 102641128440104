import DashboardLayout from 'src/pages/Layout/DashboardLayout.vue';
import AuthLayout from 'src/pages/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';
import { authGuard } from '../auth/authGuard';

// Outreach
const OutreachEvents = () =>
  import(
    /* webpackChunkName: "outreach" */ 'src/pages/Outreach/OutreachEvents.vue'
  );
const OutreachShifters = () =>
  import(
    /* webpackChunkName: "outreach" */ 'src/pages/Outreach/OutreachShifters.vue'
  );
const OutreachConversations = () =>
  import(
    /* webpackChunkName: "outreach" */ 'src/pages/Outreach/OutreachConversations.vue'
  );
const OutreachConversation = () =>
  import(
    /* webpackChunkName: "outreach" */ 'src/pages/Outreach/OutreachConversation.vue'
  );

// Calendar
const Calendar = () =>
  import(
    /* webpackChunkName: "extra" */ 'src/pages/Calendar/CalendarRoute.vue'
  );
// Charts
const Charts = () =>
  import(/* webpackChunkName: "dashboard" */ 'src/pages/Charts.vue');

// Components pages
const Buttons = () =>
  import(
    /* webpackChunkName: "components" */ 'src/pages/Components/Buttons.vue'
  );
const GridSystem = () =>
  import(
    /* webpackChunkName: "components" */ 'src/pages/Components/GridSystem.vue'
  );
const Panels = () =>
  import(
    /* webpackChunkName: "components" */ 'src/pages/Components/Panels.vue'
  );
const SweetAlert = () =>
  import(
    /* webpackChunkName: "components" */ 'src/pages/Components/SweetAlert.vue'
  );
const Notifications = () =>
  import(
    /* webpackChunkName: "components" */ 'src/pages/Components/Notifications.vue'
  );
const Icons = () =>
  import(/* webpackChunkName: "components" */ 'src/pages/Components/Icons.vue');
const Typography = () =>
  import(
    /* webpackChunkName: "components" */ 'src/pages/Components/Typography.vue'
  );

// Dashboard pages
const Dashboard = () =>
  import(
    /* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard.vue'
  );
import Widgets from 'src/pages/Widgets.vue';

// Forms pages
const RegularForms = () => import('src/pages/Forms/RegularForms.vue');
const ExtendedForms = () => import('src/pages/Forms/ExtendedForms.vue');
const ValidationForms = () => import('src/pages/Forms/ValidationForms.vue');
const Wizard = () => import('src/pages/Forms/Wizard.vue');

// Maps pages
const GoogleMaps = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Maps/GoogleMaps.vue');
const FullScreenMap = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Maps/FullScreenMap.vue');
const VectorMaps = () =>
  import(/* webpackChunkName: "extra" */ 'src/pages/Maps/VectorMaps.vue');

// Pages
const User = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/UserProfile.vue');
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Pricing.vue');
const TimeLine = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/TimeLinePage.vue');
const Login = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Login.vue');
const Register = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Register.vue');
const RTL = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/RTLPage.vue');
const Lock = () =>
  import(/* webpackChunkName: "pages" */ 'src/pages/Pages/Lock.vue');

// TableList pages
const RegularTables = () =>
  import(/* webpackChunkName: "tables" */ 'src/pages/Tables/RegularTables.vue');
const ExtendedTables = () =>
  import(
    /* webpackChunkName: "tables" */ 'src/pages/Tables/ExtendedTables.vue'
  );
const PaginatedTables = () =>
  import(
    /* webpackChunkName: "tables" */ 'src/pages/Tables/PaginatedTables.vue'
  );

let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  name: 'Components',
  children: [
    {
      path: 'buttons',
      name: 'Buttons',
      components: { default: Buttons },
      beforeEnter: authGuard,
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      components: { default: GridSystem },
      beforeEnter: authGuard,
    },
    {
      path: 'panels',
      name: 'Panels',
      components: { default: Panels },
      beforeEnter: authGuard,
    },
    {
      path: 'sweet-alert',
      name: 'Sweet Alert',
      components: { default: SweetAlert },
      beforeEnter: authGuard,
    },
    {
      path: 'notifications',
      name: 'Notifications',
      components: { default: Notifications },
      beforeEnter: authGuard,
    },
    {
      path: 'icons',
      name: 'Icons',
      components: { default: Icons },
      beforeEnter: authGuard,
    },
    {
      path: 'typography',
      name: 'Typography',
      components: { default: Typography },
      beforeEnter: authGuard,
    },
  ],
};
let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/regular',
  name: 'Forms',
  beforeEnter: authGuard,
  children: [
    {
      path: 'regular',
      name: 'Regular Forms',
      components: { default: RegularForms },
      beforeEnter: authGuard,
    },
    {
      path: 'extended',
      name: 'Extended Forms',
      components: { default: ExtendedForms },
      beforeEnter: authGuard,
    },
    {
      path: 'validation',
      name: 'Validation Forms',
      components: { default: ValidationForms },
      beforeEnter: authGuard,
    },
    {
      path: 'wizard',
      name: 'Wizard',
      components: { default: Wizard },
      beforeEnter: authGuard,
    },
  ],
};

let tablesMenu = {
  path: '/table-list',
  component: DashboardLayout,
  redirect: '/table-list/regular',
  name: 'Tables',
  beforeEnter: authGuard,
  children: [
    {
      path: 'regular',
      name: 'Regular Tables',
      components: { default: RegularTables },
      beforeEnter: authGuard,
    },
    {
      path: 'extended',
      name: 'Extended Tables',
      components: { default: ExtendedTables },
      beforeEnter: authGuard,
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      components: { default: PaginatedTables },
      beforeEnter: authGuard,
    },
  ],
};

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  name: 'Maps',
  redirect: '/maps/google',
  beforeEnter: authGuard,
  children: [
    {
      path: 'google',
      name: 'Google Maps',
      components: { default: GoogleMaps },
      beforeEnter: authGuard,
    },
    {
      path: 'full-screen',
      name: 'Full Screen Map',
      meta: {
        hideContent: true,
        hideFooter: true,
      },
      components: { default: FullScreenMap },
      beforeEnter: authGuard,
    },
    {
      path: 'vector-map',
      name: 'Vector Map',
      components: { default: VectorMaps },
      beforeEnter: authGuard,
    },
  ],
};

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  name: 'Pages',
  redirect: '/pages/user',
  beforeEnter: authGuard,
  children: [
    {
      path: 'user',
      name: 'User Page',
      components: { default: User },
      beforeEnter: authGuard,
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      components: { default: TimeLine },
      beforeEnter: authGuard,
    },
    {
      path: 'rtl',
      name: 'RTL Page',
      components: { default: RTL },
      beforeEnter: authGuard,
    },
  ],
};

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  beforeEnter: authGuard,
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      beforeEnter: authGuard,
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      beforeEnter: authGuard,
    },
    {
      path: '/pricing',
      name: 'Pricing',
      component: Pricing,
      beforeEnter: authGuard,
    },
    {
      path: '/lock',
      name: 'Lock',
      component: Lock,
      beforeEnter: authGuard,
    },
  ],
};

const routes = [
  {
    path: '/',
    redirect: '/shifters',
    name: 'Home',
    beforeEnter: authGuard,
  },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  authPages,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    name: 'Dashboard layout',
    beforeEnter: authGuard,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        components: { default: Dashboard },
        beforeEnter: authGuard,
      },
      {
        path: 'calendar',
        name: 'Calendar',
        components: { default: Calendar },
        beforeEnter: authGuard,
      },
      {
        path: 'charts',
        name: 'Charts',
        components: { default: Charts },
        beforeEnter: authGuard,
      },
      {
        path: 'widgets',
        name: 'Widgets',
        components: { default: Widgets },
        beforeEnter: authGuard,
      },
      {
        path: 'events',
        name: 'Events',
        components: { default: OutreachEvents },
        beforeEnter: authGuard,
      },
      {
        path: 'shifters',
        name: 'Shifters',
        components: { default: OutreachShifters },
        beforeEnter: authGuard,
      },
      {
        path: 'conversations/:campaignId',
        name: 'Conversations',
        components: { default: OutreachConversations },
        beforeEnter: authGuard,
      },
      {
        path: 'conversation/:conversationId',
        name: 'Conversation',
        components: { default: OutreachConversation },
        beforeEnter: authGuard,
      },
    ],
  },
  { path: '*', component: NotFound },
];

export default routes;
