import Vue from 'vue';

const outreachEvents = {
  namespaced: true,
  state: () => ({
    outreachEvents: [],
    selectedEventId: '',
    isEditing: false,
    sortData: null,
  }),
  mutations: {
    setOutreachEvents(state, outreachEvents) {
      state.outreachEvents = outreachEvents;
    },
    setSelectedEventId(state, eventId) {
      state.selectedEventId = eventId || '';
    },
    setEditing(state, isEditing) {
      state.isEditing = isEditing;
    },
    setSortData(state, sortData) {
      state.sortData = sortData;
    },
  },
  actions: {
    loadEvents(context) {
      const END_POINT = '/outreach';

      Vue.axios
        .get(END_POINT)
        .then(function (response) {
          const outreachEvents = response.data || [];
          if (outreachEvents.length > 0) {
            context.commit('setOutreachEvents', outreachEvents);

            context.dispatch('sortTable');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    createEvent(context, data) {
      const END_POINT = '/outreach';

      Vue.axios
        .post(END_POINT, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          // TODO: Navigate to the new event
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    startEvent(context, eventId) {
      const END_POINT_ACTIVATE = `/outreach/activate/${eventId}`;
      const END_POINT_START = `/sms/start/${eventId}`;

      // Activate and start the event
      Vue.axios
        .post(END_POINT_ACTIVATE)
        .then(function (response) {
          Vue.axios.post(END_POINT_START).catch(function (error) {
            console.log(error);
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // editAction(context, data) {
    //   const userId = context.getters.selectedUserId;

    //   const END_POINT = `/api/onboardings/${userId}`;

    //   const payload = {
    //     firstName: data.firstName,
    //     lastName: data.lastName,
    //     email: data.email,
    //     phone: data.phone,
    //     smsAllowed: data.smsAllowed,
    //     worksiteCode: data.worksiteCode,
    //     deleted: false,
    //   };

    //   Vue.axios
    //     .put(END_POINT, payload, {
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //     })
    //     .then(function (response) {
    //       if (response.status === 200) {
    //         context.dispatch('loadUsers');
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
    // deleteAction({ commit }, id) {
    //   const END_POINT = `/api/onboardings/${id}`;

    //   Vue.axios
    //     .delete(END_POINT)
    //     .then(function (response) {
    //       if (response.status === 200) {
    //         context.dispatch('loadUsers');
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
    setSortData({ commit }, sortData) {
      commit('setSortData', sortData);
    },
    // sortTable({ commit, getters }) {
    //   const sortData = getters.sortData;

    //   if (!sortData) return;

    //   const users = getters.users;

    //   if (!users) return;

    //   switch (sortData.order) {
    //     case 'ascending':
    //       users?.sort((a, b) => (a[sortData.prop] > b[sortData.prop] ? 1 : -1));
    //       break;
    //     case 'descending':
    //       users?.sort((a, b) => (b[sortData.prop] > a[sortData.prop] ? 1 : -1));
    //       break;
    //   }

    //   commit('setOnboardingUsers', users);
    // },
    setUserIdSelection({ commit }, userId) {
      commit('setSelectedUserId', userId);
    },
    setEditing({ commit }, isEditing) {
      commit('setEditing', isEditing);
    },
  },
  getters: {
    outreachEvents: (state) => {
      return state.outreachEvents;
    },
    selectedUserId: (state) => {
      return state.selectedUserId;
    },
    isEditing: (state) => {
      return state.isEditing;
    },
    sortData: (state) => {
      return state.sortData;
    },
  },
};

export default outreachEvents;
