import Vue from 'vue';

const jobs = {
  namespaced: true,
  state: () => ({
    jobs: [],
    selectedJobId: '',
  }),
  mutations: {
    setJobs(state, jobs) {
      state.jobs = jobs;
    },
    setSelectedJobId(state, jobId) {
      state.selectedJobId = jobId || '';
    },
  },
  actions: {
    loadJobs({ commit }) {
      const END_POINT = '/outreach/jobs';

      Vue.axios
        .get(END_POINT)
        .then(function (response) {
          const jobs = response.data || [];
          commit('setJobs', jobs);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setJobIdSelection({ commit }, jobId) {
      commit('setSelectedJobId', jobId);
    },
  },
  getters: {
    jobs: (state) => {
      return state.jobs;
    },
    selectedJobId: (state) => {
      return state.selectedJobId;
    },
  },
};

export default jobs;
