<template>
  <div class="row mt-5">
    <div class="col-12">
      <card card-body-classes="table-full-width">
        <h4 slot="header" class="card-title"></h4>
        <div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-select
              class="select-primary mb-3 pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
              <el-option class="select-primary" label="ALL" value="100000">
              </el-option>
            </el-select>

            <base-input
              disabled
              type="search"
              class="mb-3 search-input"
              clearable
              placeholder="Search"
              v-model="searchQuery"
              aria-controls="datatables"
            >
            </base-input>
          </div>
          <el-table
            ref="shifterTableRef"
            :data="queriedData"
            @sort-change="sortChange"
            @selection-change="selectionChange"
          >
            <el-table-column width="60" type="selection" align="center" />
            <el-table-column
              v-for="column in columns"
              :key="column.name"
              :prop="toCamelCase(column.name)"
              :label="column.name"
              :min-width="column.minWidth || 0"
              sortable="custom"
              :sort-orders="['ascending', 'descending']"
              :formatter="column.formatter || undefined"
              :width="column.width || undefined"
            ></el-table-column>
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category no-text-transform">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} shifters
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </base-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';

export default {
  name: 'outreach-shifters-table',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns',
    },
    rowData: {
      type: Array,
      default: () => [],
      description: 'Table data',
    },
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.rowData, {
      keys: ['title', 'jobName'],
      threshold: 0.3,
    });
  },
  watch: {
    // TODO: Change to server-side filtering.
    searchQuery(value) {
      let result = this.rowData;
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result;
    },
  },
  methods: {
    sortChange(data) {
      this.$emit('sort-action', data.prop, data.order);
    },
    toCamelCase(str) {
      return str
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
    },
    selectionChange(selections) {
      this.$store.commit('outreachShifters/setSelectedShifters', selections);
    },
  },
  computed: {
    queriedData() {
      let result = this.rowData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.rowData.length;
    },
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0,
      },
      searchQuery: '',
      searchedData: [],
      fuseSearch: null,
    };
  },
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content {
  font-size: inherit !important;
}
</style>
