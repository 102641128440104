import Vue from 'vue';
import Vuex from 'vuex';
import jobs from './modules/jobs';
import outreachEvents from './modules/outreachEvents';
import outreachShifters from './modules/outreachShifters';
import outreachConversations from './modules/outreachConversations';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    basApiUri: process.env.VUE_APP_API_URI || 'http://localhost:5000',
    authToken: null,
  },
  getters: {
    baseApiUri: (state) => state.basApiUri,
    authToken: (state) => state.authToken,
  },
  mutations: {
    baseApiUri(state, basApiUri) {
      state.basApiUri = basApiUri;
    },
    authToken(state, token) {
      state.authToken = token;
    },
  },
  actions: {},
  modules: {
    jobs,
    outreachEvents,
    outreachShifters,
    outreachConversations,
  },
});
