import Vue from 'vue';

const outreachConversations = {
  namespaced: true,
  state: () => ({
    conversations: [],
    conversation: null,
  }),
  mutations: {
    setOutreachConversations(state, conversations) {
      // Conversation list
      state.conversations = conversations;
    },
    setOutreachConversation(state, conversation) {
      // Single conversation
      state.conversation = conversation;
    },
  },
  actions: {
    loadConversations(context, campaignId) {
      const END_POINT = `/outreach/conversations/${campaignId}`;

      Vue.axios
        .get(END_POINT)
        .then(function (response) {
          const data = response.data || [];
          if (data) {
            context.commit('setOutreachConversations', data);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadConversation(context, conversationId) {
      const END_POINT = `/outreach/conversation/${conversationId}`;

      Vue.axios
        .get(END_POINT)
        .then(function (response) {
          const data = response.data || [];
          if (data) {
            context.commit('setOutreachConversation', data);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteConversations(context, campaignId) {
      const END_POINT = `/outreach/conversations/${campaignId}`;

      Vue.axios
        .delete(END_POINT)
        //.then(function (response) {})
        .catch(function (error) {
          console.log(error);
        });
    },
    sendMessage(context, message) {
      const END_POINT = '/sms/sendmessage';

      Vue.axios
        .post(END_POINT, message, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          if ([200, 201].indexOf(response.status) > -1) {
            // Reload the conversation to show the new message.
            const conversationId = context.getters.conversation._id.toString();
            context.dispatch('loadConversation', conversationId);
          }
        });
    },
  },
  getters: {
    conversations: (state) => state.conversations,
    conversation: (state) => state.conversation,
    messageHistory: (state) => state.conversation?.messageHistory,
    shifterName: (state) => state.conversation?.name,
  },
};

export default outreachConversations;
